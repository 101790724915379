<template>
    <div
        id="default-carousel"
        class="relative h-auto md:h-80 lg:h-auto overflow-hidden"
        @mouseover="stopAutoScroll"
        @mouseleave="autoScroll"
    >
        <!-- Carousel wrapper -->
        <div class="flex transition-transform duration-500" :style="{ transform: `translateX(-${currentIndex * 100}%)` }">
          <section class="bg-white rounded-lg flex-shrink-0 w-full" v-for="(item, index) in items" :key="index">
              <div class="max-w-screen-xl px-4 py-8 mx-auto text-center lg:py-16 lg:px-6">
                  <figure class="max-w-screen-md mx- md:mx-5 lg:mx-auto">
                      <svg class="h-12 mx-auto mb-3 text-gray-400" viewBox="0 0 24 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M14.017 18L14.017 10.609C14.017 4.905 17.748 1.039 23 0L23.995 2.151C21.563 3.068 20 5.789 20 8H24V18H14.017ZM0 18V10.609C0 4.905 3.748 1.038 9 0L9.996 2.151C7.563 3.068 6 5.789 6 8H9.983L9.983 18L0 18Z" fill="currentColor"/>
                      </svg> 
                      <blockquote>
                          <p class="text-base md:text-xl lg:text-2xl mx-5 md:mx-1 lg:mx-0 font-medium text-gray-900">
                              {{ item.message }}
                          </p>
                      </blockquote>
                      <div class="flex items-center justify-center pt-5">
                        <svg class="w-4 h-4 text-yellow-300 me-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 22 20">
                            <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z"/>
                        </svg>
                        <svg class="w-4 h-4 text-yellow-300 me-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 22 20">
                            <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z"/>
                        </svg>
                        <svg class="w-4 h-4 text-yellow-300 me-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 22 20">
                            <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z"/>
                        </svg>
                        <svg class="w-4 h-4 text-yellow-300 me-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 22 20">
                            <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z"/>
                        </svg>
                        <svg class="w-4 h-4 text-yellow-300 me-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 22 20">
                            <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z"/>
                        </svg>
                        <!-- <svg class="w-4 h-4 text-gray-300 me-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 22 20">
                            <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z"/>
                        </svg> -->
                          <p class="ms-1 text-sm font-medium text-gray-500">5</p>
                          <p class="ms-1 text-sm font-medium text-gray-500">out of</p>
                          <p class="ms-1 text-sm font-medium text-gray-500">5</p>
                      </div>
                      <figcaption class="flex items-center justify-center mt-6 space-x-3 pb-5 md:pb-1 lg:pb-0">
                          <img class="w-6 h-6 rounded-full" :src="item.img" alt="profile picture">
                          <div class="flex items-center divide-x-2 divide-gray-500 ">
                              <div class="pr-3 font-medium text-gray-900 text-sm">{{ item.customer_name }}</div>
                              <div class="pl-3 text-sm font-light text-gray-500">{{ item.tripType }}</div>
                          </div>
                      </figcaption>
                  </figure>
              </div>
          </section>
        </div>
        <!-- Slider indicators -->
        <div class="absolute z-30 flex -translate-x-1/2 bottom-5 left-1/2 space-x-3">
            <button
                v-for="(item, index) in items"
                :key="index"
                type="button"
                class="w-3 h-3 rounded-full"
                :class="{ 'bg-blue-500': currentIndex === index, 'bg-gray-300': currentIndex !== index }"
                @click="goToSlide(index)"
                name="slider indicators"
            ></button>
        </div>

        <!-- Slider controls -->
        <button
            type="button"
            @click="prevSlide"
            class="absolute top-0 left-0 z-30 flex items-center justify-center h-full px-2 md:px-2 lg:px-4 rounded-full"
            name="previous button"
        >
            <!-- Previous Button SVG -->
            <!-- <svg class="w-4 h-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
            <path stroke="currentColor" d="M5 1 1 5l4 4" />
            </svg> -->
            <svg class="w-4 h-4 text-blue-500  rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 1 1 5l4 4"/>
            </svg>
        </button>
        <button
            type="button"
            @click="nextSlide"
            class="absolute top-0 right-0 z-30 flex items-center justify-center h-full px-4 rounded-full"
            name="next button"
        >
            <!-- Next Button SVG -->
            <!-- <svg class="w-4 h-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
            <path stroke="currentColor" d="M1 9 5 5 1 1" />
            </svg> -->
            <svg class="w-4 h-4 text-blue-500  rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4"/>
            </svg>
        </button>
    </div>
</template>

<script>
export default {
  data() {
    return {
      currentIndex: 0,
      interval: null,
      items: [
        {   message: `Superb experience... from Naia to Boracay vice versa wala na kaming inalala, 
                        super friendly ng naging guide namin, from time to time minemessage kami ng airprove para mangamusta.. 
                        all in all.. great 1st time experience 🥰🥰🥰`,
            img: 'https://www.shutterstock.com/image-vector/default-avatar-profile-icon-social-600nw-1677509740.jpg',
            customer_name: 'Owen Ordan',
            tripType: 'Boracay Tour Package'
        },
        {   message: `Hello Christian just want to say Thank you for all your assitance on our Domestic trip... will recommend you... 😎`,
            img: 'https://www.shutterstock.com/image-vector/default-avatar-profile-icon-social-600nw-1677509740.jpg',
            customer_name: 'Anonymous',
            tripType: 'Domestic Trip'
        },
        {   message: `Thank you din po for easy transaction 😊`,
            img: 'https://www.shutterstock.com/image-vector/default-avatar-profile-icon-social-600nw-1677509740.jpg',
            customer_name: 'John Roy Galvez',
            tripType: 'Malaysia Trip'
        },
        {   message: `🤍 Airprove Travel Agency Thank You For the Smooth Transactions 🌴🤍 #familybonding`,
            img: 'https://www.shutterstock.com/image-vector/default-avatar-profile-icon-social-600nw-1677509740.jpg',
            customer_name: 'Mannilyn Santiago Tawagon',
            tripType: 'Boracay Trip'
        },
        {   message: `Thanks most of all for your great service and precise attention to all the details of our travels.`,
            img: 'https://www.shutterstock.com/image-vector/default-avatar-profile-icon-social-600nw-1677509740.jpg',
            customer_name: 'Maelyn Deguzman',
            tripType: 'Boracay Trip'
        },
      ],
    };
  },
  methods: {
    nextSlide() {
      this.currentIndex = (this.currentIndex + 1) % this.items.length;
    },

    prevSlide() {
      this.currentIndex = (this.currentIndex - 1 + this.items.length) % this.items.length;
    },

    goToSlide(index) {
      this.currentIndex = index;
    },

    autoScroll() {
      this.interval = setInterval(this.nextSlide, 3000); // Change slide every 3 seconds
    },

    stopAutoScroll() {
      clearInterval(this.interval);
    },
  },

  mounted() {
    this.autoScroll();
  },

  beforeUnmount() {
    this.stopAutoScroll();
  },
};
</script>

<style>
    .carousel-image {
        width: 100%;
        height: 100%;
        object-fit: cover; /* Ensure the image covers the entire container */
    }
</style>
