<template>
  <nav class="bg-inherit border-gray-200" id="navbar">
    <div class="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto py-4">
      <a href="https://myairprove.com/" class="flex items-center space-x-3 rtl:space-x-reverse">
          <img src="../assets/company_logo/Airprove-white_logo-NOBG.png" class="w-16 h-16" alt="AirProve Logo" />
          <span class="self-center text-2xl text-white font-semibold whitespace-nowrap">AirProve</span>
      </a>
      <button @click="toggle" value="" data-collapse-toggle="navbar-default" type="button"
          class="inline-flex items-center p-2 ml-3 text-sm text-gray-500 rounded-lg md:hidden hover:bg-transparent focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
          aria-controls="navbar-default" aria-expanded="false">
          <span class="sr-only">Open main menu</span>
          <svg class="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd"></path>
          </svg>
      </button>

      <div :class="open ? 'block' : 'hidden'" class="w-full md:block md:w-auto" id="navbar-default">
        <ul class="bg-opacity-50 bg-blur-md backdrop-filter backdrop-blur-md backdrop-saturate-50 rounded-lg border border-gray-300 font-medium flex flex-col p-4 md:p-0 mt-4 md:flex-row md:space-x-8 rtl:space-x-reverse md:mt-0 md:border-0 md:bg-transparent">
          <li>
            <a href="#" @click="goBack" class="block py-2 px-3 text-white rounded hover:text-slate-500 hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0">Home</a>
          </li>
          <li>
            <a href="#about" @click="goBack" class="block py-2 px-3 text-white rounded hover:text-slate-500 hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0">About</a>
          </li>
          <li>
            <button @click="servicesPage" class="block py-2 px-3 text-white rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0">Services</button>
          </li>
          <li>
            <button @click="pricingPage" class="block py-2 px-3 text-white rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0">Pricing</button>
          </li>
          <li>
            <a href="#feedback" class="block py-2 px-3 text-white rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0">Feedback</a>
          </li>
          <li>
            <button @click="upcoming" class="block py-2 px-3 text-white rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0">Upcoming</button>
          </li>
          <li>
            <a href="#contact" class="block py-2 px-3 text-white rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0">Contact</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>

export default{
  props: { value: Boolean },
  data() {
        return {
            open: false,
        }
    },
    watch: {
      value(newValue) {
          if (newValue === true) {
              this.open = false;
          } else {
              this.open = newValue;
          }
          // console.log("From watcher: ", this.open);
      }
    },

    methods:{
        toggle(){
            try{
              this.open = !this.open;
              this.$emit('sendData', { message: this.open });
              // console.log("This is the status of navbar: ",this.open);
            } catch(error){
              console.log(error.message)
            }
        },
        
        servicesPage(){
          this.$router.push({name: 'services'})
        },

        pricingPage(){
          this.$router.push({name: 'pricing'})
        },

        upcoming(){
          this.$router.push({name: 'upcomingPage'})
        },

        goBack(){
          this.$router.push({name: 'Home'});
        },
    }
}
</script>

<style>
#navbar{
  z-index: 1;
}

</style>