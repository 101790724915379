import { createRouter, createWebHistory } from 'vue-router'
import LandingPage from '../views/LandingPage.vue'
import OutWardBound from '../views/OutwardBound.vue'
import CultureCurious from '../views/CultureCurious.vue'
import RelaxPage from '../views/RelaxPage'
import ServicesPage from '../views/ServicesPage.vue'
import PricingPage from '../views/PricingPage.vue'
import OurCompany from '../views/OurCompany.vue'
import LoginAdmin from '../views/LoginAdmin.vue'
import HomeAdmin from '../views/HomeAdmin.vue'
import DomesticAdmin from '../views/DomesticAdmin.vue'
import InternationalAdmin from '../views/InternationalAdmin.vue'
import { checkAdminStatus } from '../checkAdminStatus.js'
import reloadPage from '../views/reloadPage.vue'
import HolidayAdmin from '../views/HolidayAdmin.vue'
import SpecificTrip from '../views/SpecificTrip.vue'
import employeeJustin from '../views/employee-justin.vue'
import josephLouie from '../views/employee-jolo.vue'
import employeeChristian from '../views/employee-christian.vue'
import NotFound from '../views/NotFound.vue'
import ReviewAdmin from '../views/ReviewAdmin.vue'
import FlightsCatalog from '../views/FlightsCatalog.vue'
import CustomBookings from '../views/CustomBookings.vue'
import TermsConditions from '../views/TermsConditions.vue'
import UpcomingPage from '../views/UpcomingPage.vue'

const adminRoutes = [
  {
    path: '/admin/bookings/cutomized',
    name: 'admin-customized',
    component: CustomBookings,
    beforeEnter: adminGuard,
  },
  {
    path: '/admin/bookings',
    name: 'admin-home',
    component: HomeAdmin,
    beforeEnter: adminGuard,
  },
  {
    path: '/admin/domestic',
    name: 'admin-domestic',
    component: DomesticAdmin,
    beforeEnter: adminGuard,
  },
  {
    path: '/admin/international',
    name: 'admin-international',
    component: InternationalAdmin,
    beforeEnter: adminGuard,
  },
  {
    path: '/admin/holiday',
    name: 'admin-holiday',
    component: HolidayAdmin,
    beforeEnter: adminGuard,
  },
  {
    path: '/admin/reviews',
    name: 'admin-reviews',
    component: ReviewAdmin,
    beforeEnter: adminGuard,
  },
];

const otherRoutes = [ 
    {
        path: '/',
        name: 'Home',
        component: LandingPage,
        meta: { title: 'Cheap Flights | Hotels | Tour Packages | AirProve — Home', 
                description: 'Affordable flights, hotels, tour packages, and travel services. Book cheap flights and plan your next adventure with AirProve.',
                ogTitle: 'Cheap Flights | Cheap Hotel | Tour Package | AirProve — Home',
                ogDescription: 'Affordable flights, hotels, tour packages, and travel services. Book cheap flights and plan your next adventure with AirProve.',
                ogImge: 'https://myairprove.com/airprove_logo.jpg',
                ogUrl: 'https://myairprove.com/'
        },
    },
    {
        path: '/Specialties/Travel-Tips-Outward-bound',
        name: 'outward',
        component: OutWardBound,
        meta: { title: 'Travel Tips | Embrace the Journey | AirProve — Ourward-bound',
                description: 'Explore essential travel tips to embrace every journey with confidence. Whether you’re a first-time traveler or a seasoned explorer, AirProve’s insights will help you make the most of your adventures, big or small.',
                ogTitle: 'Travel Tips | Embrace the Journey | AirProve — Ourward-bound',
                ogDescription: 'Explore essential travel tips to embrace every journey with confidence. Whether you’re a first-time traveler or a seasoned explorer, AirProve’s insights will help you make the most of your adventures, big or small.',
                ogImge: 'https://myairprove.com/airprove_logo.jpg',
                ogUrl: 'https://myairprove.com/Specialties/Travel-Tips-Outward-bound'
        },
    },
    {
        path: '/Specialties/Travel-Tips-Culture-curious',
        name: 'culture',
        component: CultureCurious,
        meta: { title: 'Travel Tips | Navigating Global Traditions | AirProve — Culture-Curious',
                description: 'Discover travel tips for immersing yourself in the rich tapestry of global traditions. AirProve helps you navigate cultural experiences that will deepen your understanding and appreciation of the world’s diverse cultures.',
                ogTitle: 'Travel Tips | Navigating Global Traditions | AirProve — Culture-Curious',
                ogDescription: 'Discover travel tips for immersing yourself in the rich tapestry of global traditions. AirProve helps you navigate cultural experiences that will deepen your understanding and appreciation of the world’s diverse cultures.',
                ogImge: 'https://myairprove.com/airprove_logo.jpg',
                ogUrl: 'https://myairprove.com/Specialties/Travel-Tips-Culture-curious',
        },
    },
    {
        path: '/Specialties/Travel-Tips-Relax-Relate-Release',
        name: 'relax',
        component: RelaxPage,
        meta: { title: 'Travel Tips | Embrace Serenity in a Busy World | AirProve — Relax, Relate, Release',
                description: 'Discover how to find peace amidst life’s chaos with travel tips focused on serenity. "Relax, Relate, Release" offers practical advice for embracing calm and mindfulness, helping you recharge and rejuvenate in today’s fast-paced world.',
                ogTitle: 'Travel Tips | Embrace Serenity in a Busy World | AirProve — Relax, Relate, Release',
                ogDescription: 'Discover how to find peace amidst life’s chaos with travel tips focused on serenity. "Relax, Relate, Release" offers practical advice for embracing calm and mindfulness, helping you recharge and rejuvenate in today’s fast-paced world.',
                ogImge: 'https://myairprove.com/airprove_logo.jpg',
                ogUrl: 'https://myairprove.com/Specialties/Travel-Tips-Relax-Relate-Release',
        },
    },
    {
        path: '/Travel-Solutions-AirProve-Services',
        name: 'services',
        component: ServicesPage,
        meta: { title: 'Travel Agency Services | Travel Solutions | AirProve — Services',
                description: 'Explore AirProve`s comprehensive travel solutions, including trip planning, customized itineraries, transportation and accommodation bookings, and expert travel consultation to ensure a seamless travel experience.',
                ogTitle: 'Travel Agency Services | Travel Solutions | AirProve — Services',
                ogDescription: 'Explore AirProve`s comprehensive travel solutions, including trip planning, customized itineraries, transportation and accommodation bookings, and expert travel consultation to ensure a seamless travel experience.',
                ogImge: 'https://myairprove.com/airprove_logo.jpg',
                ogUrl: 'https://myairprove.com/Travel-Solutions-AirProve-Services',
        },
    },
    {
        path: '/Travel-Agency-AirProve-Pricing',
        name: 'pricing',
        component: PricingPage,
        meta: { title: 'Travel Agency Services | Pricing | AirProve — Our Pricing',
                description: 'Discover our transparent pricing for trip planning, customized itineraries, transportation, accommodation bookings, and expert travel consultation, designed to fit your travel needs and budget.',
                ogTitle: 'Travel Agency Services | Pricing | AirProve — Our Pricing',
                ogDescription: 'Discover our transparent pricing for trip planning, customized itineraries, transportation, accommodation bookings, and expert travel consultation, designed to fit your travel needs and budget.',
                ogImge: 'https://myairprove.com/airprove_logo.jpg',
                ogUrl: 'https://myairprove.com/Travel-Agency-AirProve-Pricing',
                
        },
    },
    {
        path: '/AirProve-Travel-Agency-Our-team',
        name: 'team',
        component: OurCompany,
        meta: { title: 'Travel Agency Team | Travel Agents | AirProve — Our Team',
                description: 'Meet the passionate and experienced travel experts at AirProve. We are dedicated to crafting personalized, unforgettable journeys while prioritizing excellence, affordability, and customer satisfaction.',
                ogTitle: 'Travel Agency Team | Travel Agents | AirProve — Our Team',
                ogDescription: 'Meet the passionate and experienced travel experts at AirProve. We are dedicated to crafting personalized, unforgettable journeys while prioritizing excellence, affordability, and customer satisfaction.',
                ogImge: 'https://myairprove.com/airprove_logo.jpg',
                ogUrl: 'https://myairprove.com/AirProve-Travel-Agency-Out-team',
        },
    },
    {
        path: '/login/admin',
        name: 'login-admin',
        component: LoginAdmin,
    },
    {
      path: '/reload',
      name: 'reload-page',
      component: reloadPage,
    },
    {
      path: '/booking/destination=:destination/id=:id',
      name: 'specific-trip',
      component: SpecificTrip,
    },
    {
      path: '/employee-profile/john-justin-berdin',
      name: '/employee-profile/justin',
      component: employeeJustin,
    },
    {
      path: '/employee-profile/joseph-louie-solcoretes',
      name: '/employee-profile/jolo',
      component: josephLouie,
    },
    {
      path: '/employee-profile/christian-james-morisca',
      name: '/employee-profile/christian',
      component: employeeChristian,
    },
    {
      path: '/flights/:fromLoc/:toLoc/:departureDate/:returnDate',
      name: 'flightsCatalog',
      component: FlightsCatalog,
    },
    {
      path: '/Travel-Agency-Terms-TermsAndConditions',
      name: 'terms-page',
      component: TermsConditions,
      meta: { title: 'Travel Agency Terms | Travel Rules | AirProve — Terms And Conditions',
              description: 'Before making a reservation with AIRPROVE TRAVEL AGENCY, we encourage you to read these Terms and Conditions carefully, as they constitute a contract agreement between AIRPROVE TRAVEL AGENCY and the Client from the time the booking is made.',
              ogTitle: 'Travel Agency Terms | Travel Rules | AirProve — Terms And Conditions',
              ogDescription: 'Before making a reservation with AIRPROVE TRAVEL AGENCY, we encourage you to read these Terms and Conditions carefully, as they constitute a contract agreement between AIRPROVE TRAVEL AGENCY and the Client from the time the booking is made.',
              ogImge: 'https://myairprove.com/airprove_logo.jpg',
              ogUrl: 'https://myairprove.com/Travel-Agency-Terms-TermsAndConditions',
      },
    },
    {
      path: '/Coming-soon',
      name: 'upcomingPage',
      component: UpcomingPage,
    },
    { 
      path: '/:catchAll(.*)', 
      component: NotFound,
      meta: { title: '404 | Page not found.',
              description: 'Looks like you`ve ventured off the map! Don`t worry; our compass is pointing us in the right direction. In the meantime, why not explore other amazing destinations.',
              ogTitle: '404 | Page not found.',
              ogDescription: 'Looks like you`ve ventured off the map! Don`t worry; our compass is pointing us in the right direction. In the meantime, why not explore other amazing destinations.',
              ogImge: 'https://myairprove.com/airprove_logo.jpg',
      },
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes: [...otherRoutes, ...adminRoutes],
    scrollBehavior() {
      // Scroll to the top of the page on route navigation
      return { top: 0, left: 0 };
    }
});

router.afterEach((to) => {
  document.title = to.meta.title || 'AirProve Travel Agency';
});

// Global navigation guard
router.beforeEach((to, from, next) => {
  // Your global logic here (if needed)
  next();
});
async function adminGuard(to, from, next) {
  try {
    console.log('Entering adminGuard');
    const isAdmin = await checkAdminStatus();

    if (isAdmin) {
      console.log('User is an admin, allow navigation');
      next();
    } else {
      console.log('User is not authorized, redirecting to /login/admin');
      next('/login/admin');
    }
  } catch (error) {
    console.error('Error checking admin status:', error);
    console.log('Redirecting to /login/admin due to error');
    next('/login/admin'); // Handle error by redirecting to the login page
  }
}

export default router