import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import './styles/main.css';
import VueSocialSharing from 'vue-social-sharing'
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import { createHead } from '@vueuse/head';

// Set up global error handling
window.onerror = function(message, source, lineno, colno, error) {
  console.error('Error:', message, 'at', source, lineno, colno, error);
};

const app = createApp(App);
const head = createHead();
app.use(head);
app.use(router);
app.use(VueSocialSharing);
app.component('VueDatePicker', VueDatePicker);
app.config.productionTip = false;
// app.use(VueSocialSharing, {
//   networks: {
//     fakeblock: 'https://fakeblock.com/share?url=@url&title=@title',
//   }
// })

app.mount("#app");