<template>
    <nav class="bg-inherit border-gray-200">
      <div class="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4 border-b-2 border-slate-200">
        <a href="#" class="flex items-center space-x-3 rtl:space-x-reverse">
            <img src="../assets/company_logo/Airprove-blue_logo-NOBG.png" class="w-16 h-16" alt="AirProve Logo" />
            <span class="self-center text-2xl text-slate-900 font-semibold whitespace-nowrap">AirProve</span>
        </a>
        <button @click="toggle" data-collapse-toggle="navbar-default" type="button" class="inline-flex items-center p-2 ml-3 text-sm text-gray-500 rounded-lg md:hidden hover:bg-transparent focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600" aria-controls="navbar-default" aria-expanded="false">
          <span class="sr-only">Open main menu</span>
          <svg class="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd"></path>
          </svg>
        </button>

      <div :class="open ? 'block' : 'hidden'" class="w-full md:block md:w-auto" id="navbar-default">
        <ul class="font-medium flex flex-col p-4 md:p-0 mt-4 bg-transparent md:flex-row md:space-x-8 rtl:space-x-reverse md:mt-0 md:border-0 md:bg-transparent">
          <li>
            <router-link to="/admin/bookings/cutomized" class="block py-2 px-3 text-slate-900 rounded hover:text-slate-500 hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0">Custom Bookings</router-link>
          </li>
          <li>
            <router-link to="/admin/bookings" class="block py-2 px-3 text-slate-900 rounded hover:text-slate-500 hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0">Bookings</router-link>
          </li>
          <li>
            <router-link to="/admin/reviews" class="block py-2 px-3 text-slate-900 rounded hover:text-slate-500 hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0">Reviews</router-link>
          </li>
          <li>
            <router-link to="/admin/domestic" class="block py-2 px-3 text-slate-900 rounded hover:text-slate-500 hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0">Domestic</router-link>
          </li>
          <li>
            <router-link to="/admin/international" class="block py-2 px-3 text-slate-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0">International</router-link>
          </li>
          <li>
            <router-link to="/admin/holiday" class="block py-2 px-3 text-slate-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0">Holiday</router-link>
          </li>
          <li>
            <a href="#" @click="logoutBtn" class="block py-2 px-3 text-slate-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0">Logout</a>
          </li>
        </ul>
      </div>
      </div>
    </nav>
</template>

<script>
import Parse from 'parse'
export default{
  data(){
    return {
      open: false,
    }
  },
  methods:{
    goBack(){
      this.$router.push({name: 'admin-home'});
      console.log("Clicking");
    },

    domesticPage(){
      this.$router.push({name: 'admin-domestic'});
      console.log("Clicking");
    },

    internationalPage(){
      this.$router.push({name: 'admin-international'});
      console.log("Clicking");
    },

    toggle(){
      this.open = !this.open
    },

    logoutBtn(){
      Parse.User.logOut().then(()=>{
        this.$router.push({name: 'login-admin'});
        console.log('User logged out');
      }, (error) => {
        console.error("Error logging out", error)
        
      })
    }
  },

}
</script>